import styles from '@/components/models/accommodation/AccommodationTeaser/AccommodationTeaser.module.scss'
import accommodationObject from '@/entities/accommodation/interface'
import { Card } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import StarRatingDisplay from '@/components/elements/starRating/StarRatingDisplay'

interface Props {
  accommodation: accommodationObject
}
const AccommodationTeaser = ({ accommodation }: Props) => {
  return (
    <Card className={styles.root}>
      {accommodation.gallery.images.length > 0 && (
        <CloudinaryImage image={accommodation.gallery.images[0]} aspectRatio={1.777} width={635} altDefault={accommodation.name} />
      )}
      <Card.Body className={styles.cardBody}>
        <Card.Title className={styles.cardTitle}>
          <div className={`${styles.stars} clearfix`}>
            <StarRatingDisplay starsValue={accommodation.stars as unknown as string} />
          </div>
          <div>{accommodation.name}</div>
        </Card.Title>
        <Card.Text as={'div'} dangerouslySetInnerHTML={{ __html: accommodation.description }} />
      </Card.Body>
    </Card>
  )
}

export default AccommodationTeaser
